<template>
  <div>
      <b-form @submit.prevent="enviarForm">
        <b-form-row>

          <b-col cols="12">
              <label  for="empresa">Empresa</label>
              <div class="d-flex align-items-center">
                  <b-form-input
                      id="empresa"
                      class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                      placeholder="Empresa"
                      v-model="form.empresa"
                      readonly
                  ></b-form-input>
                  <b-icon icon="search" variant="danger"  @click="activarModalBuscar" class="mb-1"></b-icon>
                  <b-icon icon="x-circle" variant="danger" @click="limpiarEmpresa" v-if="this.form.id_empresa!=null" class="mb-1 ml-1"></b-icon>
              </div>
          </b-col>

        </b-form-row>

        <b-row align-h="end">

            <b-col cols="12" md="8">
               <label  for="acta">Num. Acta</label>
                <b-form-input
                    id="acta"
                    class="mb-2 mr-sm-2 mb-sm-0 mr-2"
                    placeholder="acta"
                    v-model="form.numacta"
                    disable="form.id_empresa != null"
                ></b-form-input>
            </b-col>

            <b-col cols="12" md="4">
                <b-button class="mt-4 vertical-align" variant="secondary" type="submit" block>
                    <b-icon icon="plus-circle"></b-icon>
                    Consultar
                </b-button>
            </b-col>
        </b-row>
    </b-form>
    <b-modal  
        id="modalBuscar" 
        v-model="mostrarModalBuscarBoolean" 
        hide-footer size="lg" 
        @hide="cerrarModalBuscar">
        <template #modal-title>
            Buscar Empresas
        </template>
        <div class="d-block text-center p-3">
            <FormularioBuscarEmpresas />
        </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {encontrarError} from '../../../contantesErrores'
import FormularioBuscarEmpresas from '../consultaEmpresas/buscadorEmpresa/FormularioBuscarEmpresas.vue'

export default {
    name: 'FormularioConsultaGestionVisita',
    components:{
      FormularioBuscarEmpresas
    },
    data(){
      return{
        form: {
          id_empresa: null,
          empresa: null,
          numacta: null,
        }
      }
    },
    mounted(){
      this.$root.$on('bv::modal::hide', () => {
          if(this.empresaSeleccionada.id){
              this.form.id_empresa = this.empresaSeleccionada.id
              this.form.empresa = this.empresaSeleccionada.razon_social
          }
          this.resetEmpresasBuscar();
      })
    },
    computed: {
      ...mapState('consultaEmpresas', ['empresaSeleccionada', 'mostrarModalBuscarBoolean']),
    },
    methods:{
      ...mapActions('gestionarVisitas', ['cargarVisitasProgramadasVigilanciaControl', 'buscarVisitasVigilanciaControlByEmpresaId']),
      ...mapActions('consultaEmpresas', ['MostrarModalBuscar', 'resetEmpresasBuscar', 'asignarEmpresaSeleccionada']),

      activarModalBuscar(){
        if(this.form.numacta == null || this.form.numacta == '' ){
          this.MostrarModalBuscar(true)
        }
      },
      
      cerrarModalBuscar(){
          this.MostrarModalBuscar(false)
      },

      limpiarEmpresa(){
        this.form.id_empresa = null;
        this.form.empresa = null;
        this.form.numacta = null;
        this.asignarEmpresaSeleccionada(null)
      },
      
      async enviarForm(){
        if(this.form.id_empresa!=null || this.form.numacta != null){
          // let datos={
          //   idempresa: this.form.id_empresa,
          //   numacta: this.form.numacta,
          // }
          try{
            await this.buscarVisitasVigilanciaControlByEmpresaId(this.form)
          }catch(e){
            encontrarError(this, e.data)
          }
        }else {
          try{
            await this.cargarVisitasProgramadasVigilanciaControl();
          }catch(e){
            encontrarError(this, e.data)
          }
        }
      }
    }
}
</script>

<style scoped>
   
</style>